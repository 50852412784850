import { Box, Grid, Link as MuiLink, Typography } from "@mui/material";
import { Link as GatsbyLink, Link } from "gatsby";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { mapWpContent } from "../../../helpers/general/parseWpHtmlToReact/htmlTagsToReactComponents";
import { DogImage } from "../../01-atoms/image";
import WordPressImage from "../../01-atoms/WordPressImage/WordPressImage";
import { TopArticleProps } from "./TopArticleInterfaces";
import { useTopArticleStyles } from "./TopArticleStyles";

const TopArticle = (props: TopArticleProps): JSX.Element => {
  const { classes } = useTopArticleStyles();

  const articleLink = props.link;

  const mainCategoryUppercase: string = props.mainCategory?.toUpperCase() || "";

  return (
    <Box className={classes.topArticleWrapper}>
      <Grid item={true} xs={12} className={classes.image}>
        {props.image ? (
          <Link to={`/${articleLink}`}>
            <WordPressImage imageInformation={props.image} />
          </Link>
        ) : (
          <DogImage />
        )}
      </Grid>

      <Grid item={true} xs={12}>
        <MuiLink component={GatsbyLink} to={`/kategorie/${props.categorySlug}`}>
          <Typography component="span" variant="h6" color="secondary.dark">
            {mainCategoryUppercase}
          </Typography>
        </MuiLink>
      </Grid>

      <Grid item={true} xs={12}>
        <Typography mb={1} variant="h1">
          <MuiLink component={GatsbyLink} to={`/${articleLink}`}>
            {props.title}
          </MuiLink>
        </Typography>
      </Grid>

      <Grid item={true} xs={12}>
        <Typography mt={-0.5} mb={5} variant="body1">
          {replaceHtmlWithFunctionComponent(props.excerpt, mapWpContent)}
        </Typography>
      </Grid>
    </Box>
  );
};

export default TopArticle;
