import { Article } from "../../components/03-organisms/GridWithPosts/GridWithPosts";
import { isSubCategory } from "./isSubCategory";

export function getSubCategorySlugForArticle(
  article: Article
): string | undefined {
  const allArticleCategories = article?.categories?.nodes || [];
  const singleSubCategory = allArticleCategories.find(isSubCategory);

  const subCategorySlug = singleSubCategory?.slug;

  return subCategorySlug;
}
