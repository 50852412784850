import { Article } from "../../components/03-organisms/GridWithPosts/GridWithPosts";
import { isMainCategory } from "./isMainCategory";

export function getMainCategorySlugForArticle(
  article: Article
): string | undefined {
  const allArticleCategories = article?.categories?.nodes || [];
  const singleMainCategory = allArticleCategories.find(isMainCategory);

  const mainCategorySlug = singleMainCategory?.slug;

  return mainCategorySlug;
}
