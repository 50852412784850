import { makeStyles } from "tss-react/mui";

export const useTopArticleStyles = makeStyles()(() => ({
  topArticleWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  image: {
    "& .gatsby-image-wrapper": {
      maxHeight: "500px",
      minWidth: "100%",
      "& img": {
        height: "100%",
        width: "100%",
      },
    },
  },
}));
