import { WordpressImageInformation } from "../../../interfaces/WordPress/WordpressImageInformation";

export type WordPressGraphQlImageData =
  | GatsbyTypes.Maybe<
      GatsbyTypes.WpMediaBaseFieldsFragment &
        GatsbyTypes.WpMediaItemImageBackgroundFragment
    >
  | GatsbyTypes.Maybe<
      GatsbyTypes.WpMediaBaseFieldsFragment &
        GatsbyTypes.WpMediaItemImageThumbFragment
    >
  | GatsbyTypes.Maybe<
      GatsbyTypes.WpMediaBaseFieldsFragment &
        GatsbyTypes.WpMediaItemImageMediumFragment
    >;

export const mapWpImageInformation = (
  wordpressData: WordPressGraphQlImageData
): WordpressImageInformation | undefined => {
  if (wordpressData?.sourceUrl) {
    return {
      alt: wordpressData?.altText || "",
      src: wordpressData?.sourceUrl || "",
      srcset: wordpressData?.srcSet || "",
      imageSharp: wordpressData?.localFile,
    };
  }

  return undefined;
};
