import { Box, Container } from "@mui/material";
import { graphql } from "gatsby";
import { staticSeoTexts } from "../../../data/staticSeoTexts";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import { getMainCategoryForArticle } from "../../../helpers/wordpress/getMainCategoryForArticle";
import { getMainCategorySlugForArticle } from "../../../helpers/wordpress/getMainCategorySlugForArticle";
import { SeoBasics } from "../../01-atoms/SeoBasics/SeoBasics";
import TopArticle from "../../02-molecules/TopArticle/TopArticle";
import GridWithPosts from "../../03-organisms/GridWithPosts/GridWithPosts";
import HorizontalPostGrid from "../../03-organisms/HorizontalPostGrid/HorizontalPostGrid";
import { StartseiteDynamischProps } from "./StartseiteDynamischInterfaces";
import { useStartseiteDynamischStyles } from "./StartseiteDynamischStyles";
import { useStartseiteDynamischViewModel } from "./StartseiteDynamischViewModel";

export const query = graphql`
  query (
    $topArtikelId: String
    $vertikaleArikelIds: [String]
    $sliderCategoryIds: [String]
  ) {
    topArtikel: allWpPost(filter: { id: { eq: $topArtikelId } }) {
      nodes {
        ...WpPostFields
      }
    }
    vertikaleArtikel: allWpPost(filter: { id: { in: $vertikaleArikelIds } }) {
      nodes {
        ...WpPostFields
      }
    }
    sliderCategories: allWpCategory(
      filter: { id: { in: $sliderCategoryIds } }
    ) {
      nodes {
        id
        slug
        name
      }
    }
    allArticlesExeptOfTopArticleAndVerticalArticles: allWpPost(
      sort: { order: DESC, fields: date }
      filter: {
        categories: { nodes: { elemMatch: { id: { in: $sliderCategoryIds } } } }
        id: { ne: $topArtikelId, nin: $vertikaleArikelIds }
      }
    ) {
      nodes {
        ...WpPostFields
      }
    }
  }
`;

const StartseiteDynamisch = (props: StartseiteDynamischProps): JSX.Element => {
  const { data, pageContext } = props;
  // console.log("hallo, das sind die props", props);
  // console.log("hallo, das ist data", data);

  const viewModel = useStartseiteDynamischViewModel(props);
  const { classes } = useStartseiteDynamischStyles();

  const arrayWithVerticalArticlesStartseite = data.vertikaleArtikel.nodes;

  const amountOfHorizontalSliders = data.sliderCategories.nodes.length;
  // console.log(amountOfHorizontalSliders);

  let slicerStart = 0;
  let slicerEnd = 2;

  // console.log(
  //   "2 Vertikale Artikel Slice(" + slicerStart + "," + slicerEnd + ")"
  // );
  data.sliderCategories.nodes.forEach((cat) => {
    const result =
      data.allArticlesExeptOfTopArticleAndVerticalArticles.nodes.filter(
        (article) => {
          return article.categories.nodes
            .map((category) => category.id)
            .includes(cat.id);
        }
      );
    // console.log(result);
    // console.log("1 Slider");

    slicerStart = slicerStart + 2;
    slicerEnd = slicerEnd + 2;

    // console.log(
    //   "2 Vertikale Artikel Slice(" + slicerStart + "," + slicerEnd + ")"
    // );
  });

  const firstFourVerticalArticles = data.vertikaleArtikel.nodes.slice(0, 2);

  const mappedImageTopArticle = data.topArtikel.nodes[0].featuredImage
    ? // Übersetzer WP zu Gatsby Image
      mapWpImageInformation(data.topArtikel.nodes[0].featuredImage?.node)
    : undefined;

  const sortedCategories = pageContext.sliderCategoryIds.map((categoryId) =>
    data.sliderCategories.nodes.find((category) => category.id === categoryId)
  );

  const sortedVerticalArticles = pageContext.vertikaleArikelIds.map(
    (articleId) =>
      data.vertikaleArtikel.nodes.find((article) => article.id === articleId)
  );

  return (
    <Container maxWidth="lg">
      <SeoBasics {...staticSeoTexts.startseite} />

      <div>
        <TopArticle
          link={data.topArtikel.nodes[0].slug}
          title={data.topArtikel.nodes[0].title}
          excerpt={data.topArtikel.nodes[0].excerpt}
          mainCategory={getMainCategoryForArticle(data.topArtikel.nodes[0])}
          image={mappedImageTopArticle}
          categorySlug={getMainCategorySlugForArticle(data.topArtikel.nodes[0])}
        />

        <GridWithPosts
          showMainCategory={true}
          articlesPreviewData={sortedVerticalArticles.slice(0, 2)}
        />

        {/* Für jede Kategorie auf der Startseite */}

        {sortedCategories.map((cat, index) => {
          const arrayWithFilteredArticlesByCat =
            data.allArticlesExeptOfTopArticleAndVerticalArticles.nodes.filter(
              (article) => {
                return article.categories.nodes
                  .map((category) => category.id)
                  .includes(cat.id);
              }
            );

          return (
            <Box key={index}>
              <HorizontalPostGrid
                categoryLabel={cat.name.toUpperCase()}
                categoryArticlesPreviewData={arrayWithFilteredArticlesByCat}
                categorySlug={cat.slug}
              />

              <GridWithPosts
                key={index}
                showMainCategory={true}
                articlesPreviewData={sortedVerticalArticles.slice(
                  // index des arrays der horizontalen slider "nach jedem silder sollen4 posts eingezeigt werden"
                  index * 2 + 2,
                  index * 2 + 4
                )}
              />
            </Box>
          );
        })}
      </div>
    </Container>
  );
};

export default StartseiteDynamisch;
