import { Grid } from "@mui/material";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import { getMainCategoryForArticle } from "../../../helpers/wordpress/getMainCategoryForArticle";
import { getMainCategorySlugForArticle } from "../../../helpers/wordpress/getMainCategorySlugForArticle";
import { getSubCategoryForArticle } from "../../../helpers/wordpress/getSubCategoryForArticle";
import { getSubCategorySlugForArticle } from "../../../helpers/wordpress/getSubCategorySlugForArticle";
import { Category } from "../../../interfaces/Category";
import ArticlePreview from "../../02-molecules/ArticlePreview/ArticlePreview";
import { GridWithPostsProps } from "./GridWithPostsInterfaces";
import { useGridWithPostsStyles } from "./GridWithPostsStyles";

export interface Article {
  categories: {
    nodes: Category[];
  };
}

const GridWithPosts = (props: GridWithPostsProps): JSX.Element => {
  const { classes } = useGridWithPostsStyles();

  return (
    <Grid
      className={classes.wrapper}
      container={true}
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      {props.articlesPreviewData.map((article, index) => {
        const mappedImage = article.featuredImage
          ? // Übersetzer WP zu Gatsby Image
            mapWpImageInformation(article.featuredImage?.node)
          : undefined;

        const categoryLabel = props.showMainCategory
          ? getMainCategoryForArticle(article)
          : getSubCategoryForArticle(article);

        const categorySlug = props.showMainCategory
          ? getMainCategorySlugForArticle(article)
          : getSubCategorySlugForArticle(article);

        return (
          <Grid key={index} item={true} xs={12} sm={12} md={6}>
            <ArticlePreview
              classNameArticle={classes.imagejo}
              link={article.slug}
              image={mappedImage}
              title={article.title}
              excerpt={article.excerpt}
              categoryLabel={categoryLabel}
              categorySlug={categorySlug}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default GridWithPosts;
