import { Typography } from "@mui/material";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { ArticleExcerptProps } from "./ArticleExcerptInterfaces";
import { useArticleExcerptStyles } from "./ArticleExcerptStyles";

const contentLimitInCharacters = 120;

export const ArticleExcerpt = (
  props: ArticleExcerptProps
): JSX.Element | null => {
  const { classes, cx } = useArticleExcerptStyles();

  return (
    <>
      {replaceHtmlWithFunctionComponent(props.text, {
        tagMap: {
          p: ({ children }) => {
            if (typeof children !== "string") {
              return <></>;
            }

            const limitedText =
              (children?.length || 0) > contentLimitInCharacters
                ? `${(children || "").slice(0, contentLimitInCharacters)} …`
                : children;

            const textToDisplay = props.limitContentLength
              ? limitedText
              : children;

            return (
              <Typography
                pb={0}
                variant="body1"
                gutterBottom={true}
                className={cx(classes.wrapper, props.className)}
              >
                {textToDisplay}
              </Typography>
            );
          },
        },
      })}
    </>
  );
};
