import { makeStyles } from "tss-react/mui";

export const useArticlePreviewStyles = makeStyles()((theme) => ({
  testBackground: {
    backgroundColor: "",
  },
  category: {
    width: "301px",
  },
  articleBoxHorizontal: {
    alignItems: "flex-start",
    // alignItems: "center",
    "& h2": {
      marginTop: theme.spacing(0.5),
    },
  },
  articleBox: {
    alignItems: "flex-start",
    "& h2": {
      marginTop: theme.spacing(0.5),
    },
  },

  gridUnderArticleBox: {
    maxHeight: "300px",
    width: "100%",
    // width: "301px",
  },
  gridUnderArticleBoxHorizontal: {
    // width: "333px",
  },
  centerStuff: {},

  excerptInArticlePreview: {
    wordBreak: "break-word",
    marginBottom: theme.spacing(6),
    // width: "301px",
    gridUnderArticleBox: {
      maxHeight: "300px",
      width: "100%",
      // width: "301px",
    },
  },
  excerptInArticlePreviewHorizontal: {
    wordBreak: "break-word",
  },
  wordbreak: {
    wordBreak: "break-word",
  },
  widthInArticlePreview: {},
  widthInArticlePreviewHorizontal: {
    // width: "333px",
  },
}));
