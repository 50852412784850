import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const DogImage = (): JSX.Element | null => {
  const data = useStaticQuery<GatsbyTypes.ImageQuery>(graphql`
    query Image {
      file(relativePath: { glob: "*dog*" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, quality: 80)
        }
      }
    }
  `);

  if (!data.file?.childImageSharp) {
    throw Error("DogImage not found");
  }

  return (
    <GatsbyImage alt="" image={data.file.childImageSharp.gatsbyImageData} />
  );
};
