import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { WordPressImageProps } from "./WordPressImageInterface";

const WordPressImage = (props: WordPressImageProps): JSX.Element | null => {
  const image = props.imageInformation.imageSharp
    ? getImage(props.imageInformation.imageSharp as ImageDataLike)
    : undefined;

  return image ? (
    <GatsbyImage
      image={image}
      alt={props.imageInformation.alt}
      style={{
        width: "100%",
      }}
      className={props.imageClass}
      {...props.gatsbyImageProps}
      imgClassName={props.innerImageClass}
    />
  ) : null;
};

export default WordPressImage;
