import { makeStyles } from "tss-react/mui";

export const useGridWithPostsStyles = makeStyles()((theme) => ({
  wrapper: {
    marginTop: theme.spacing(0),
  },
  verticalGridWrapper: {},
  imagejo: {
    "& .gatsby-image-wrapper": {
      // height: "301px !important",
      // neu
      height: "auto",
      // neu
      minHeight: "300px",
      // width: "301px !important",
      "& img": {
        // alt
        // height: "auto",
        maxHeight: "300px",
        // neu
        height: "100%",
      },
    },
  },
}));
