import { Box, Grid, Link as MuiLink, Typography } from "@mui/material";
import { Link as GatsbyLink, Link } from "gatsby";
import { useInterval } from "react-use";
import { ArticleExcerpt } from "../../01-atoms/ArticleExcerpt/ArticleExcerpt";
import { DogImage } from "../../01-atoms/image";
import WordPressImage from "../../01-atoms/WordPressImage/WordPressImage";
import { performGatsbyImageMagic } from "../../01-atoms/WpGutenbergImage/performGatsbyImageMagic";
import { ArticlePreviewProps } from "./ArticlePreviewInterfaces";
import { useArticlePreviewStyles } from "./ArticlePreviewStyles";

const ArticlePreview = (props: ArticlePreviewProps): JSX.Element => {
  const { classes, cx } = useArticlePreviewStyles();

  const defaultMainCategory = "SONSTIGES";

  const articleLink = props.link;

  const mainCategoryString = props.categoryLabel;

  const mainCategoryUppercase = mainCategoryString?.toUpperCase();

  const isInHorizontalGrid = props.isInsideOfHorizontalSlider;

  /**
   * 🚨
   * Trying to fix blurry images on frontpage.
   * If it works, it could/should be done more properly (globally?).
   */
  useInterval(performGatsbyImageMagic, 2500);

  return (
    // START ARTIKLE BOX CONDITION

    // END ARTIKLE BOX CONDITION

    <Box
      className={cx(
        classes.testBackground,
        props.classNameArticle,
        props.classNameImage
      )}
    >
      <Grid
        container={true}
        direction="column"
        alignItems="center"
        justifyItems="center"
        justifyContent="center"
        className={
          isInHorizontalGrid ? classes.articleBoxHorizontal : classes.articleBox
        }
      >
        <Grid
          className={
            isInHorizontalGrid
              ? classes.gridUnderArticleBoxHorizontal
              : classes.gridUnderArticleBox
          }
          item={true}
          xs={6}
          sm={true}
          width={"100%"}
        >
          {/* Image */}

          {props.image ? (
            <Link to={`/${articleLink}`}>
              <WordPressImage imageInformation={props.image} />
            </Link>
          ) : (
            // Planet
            <DogImage />
          )}
        </Grid>

        {/* Category */}

        <Grid item={true} xs={6} className={classes.category}>
          <MuiLink
            component={GatsbyLink}
            to={`/kategorie/${props.categorySlug}`}
          >
            {/* "component=span" löst das verlinken einer ganzen zeile */}

            <Typography component="span" variant="h6" color="secondary.dark">
              {mainCategoryUppercase || defaultMainCategory}
            </Typography>
          </MuiLink>
        </Grid>

        {/* TITLE START */}

        {isInHorizontalGrid === true ? (
          <Grid item={true} xs={6}>
            <Typography
              mb={0.5}
              variant="h2"
              className={cx(
                props.classNameTitle,
                classes.wordbreak,
                classes.widthInArticlePreviewHorizontal
              )}
            >
              <MuiLink component={GatsbyLink} to={`/${articleLink}`}>
                {props.title}
              </MuiLink>
            </Typography>
          </Grid>
        ) : (
          <Grid item={true} xs={6}>
            <Typography
              mb={0.5}
              variant="h2"
              className={cx(
                props.classNameTitle,
                classes.wordbreak,
                classes.widthInArticlePreview
              )}
            >
              <MuiLink component={GatsbyLink} to={`/${articleLink}`}>
                {props.title}
              </MuiLink>
            </Typography>
          </Grid>
        )}

        {/* TITLE END */}

        {/* EXCERPT */}

        {/* Je nachdem, ob es die ArtikelPreview im HorizontalGrird oder VertikalGrid ist, werden andere Klassen für das Spacing geladen */}

        {isInHorizontalGrid === true ? (
          <Grid
            className={classes.excerptInArticlePreviewHorizontal}
            item={true}
            xs={6}
          >
            <ArticleExcerpt text={props.excerpt} limitContentLength={true} />
          </Grid>
        ) : (
          <Grid className={classes.excerptInArticlePreview} item={true} xs={6}>
            <ArticleExcerpt text={props.excerpt} limitContentLength={true} />
          </Grid>
        )}

        {/* <Grid className={classes.excerptInArticlePreview} item={true} xs={6}>
          <ArticleExcerpt text={props.excerpt} limitContentLength={true} />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default ArticlePreview;
