import { Article } from "../../components/03-organisms/GridWithPosts/GridWithPosts";
import { isMainCategory } from "./isMainCategory";

export function getMainCategoryForArticle(
  article: Article
): string | undefined {
  const allArticleCategories = article.categories.nodes;
  const singleMainCategory = allArticleCategories.find(isMainCategory);

  const mainCategory = singleMainCategory?.name;

  return mainCategory;
}
